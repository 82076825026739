$(document).ready(function(){
	
	//how much items per page to show
	var show_per_page = 12; 
	//getting the amount of elements inside pagination-wrap 
	var number_of_items = $('#pagination-wrap > .image-card-wrapper').length
	//calculate the number of pages we are going to have
	var number_of_pages = Math.ceil(number_of_items/show_per_page);
	
	//set the value of our hidden input fields
	$('#current_page').val(0);
	$('#show_per_page').val(show_per_page);
	
	var navigation_html = '<a href="javascript:previous();" class="pagination-block__arrow"></a>';
	var current_link = 0;
	while(number_of_pages > current_link){
		navigation_html += '<a class="pagination-block__link pagination-block__link" href="javascript:go_to_page(' + current_link +')" longdesc="' + current_link +'">'+ (current_link + 1) +'</a>';
		current_link++;
	}
	navigation_html += '<a href="javascript:next();" class="pagination-block__arrow pagination-block__arrow--right"></a>';
	
	$('#page_navigation').html(navigation_html);
	
	//add pagination-block__link--active class to the first page link
	$('#page_navigation .pagination-block__link:first').addClass('pagination-block__link--active');
	
	//hide all the elements inside pagination-wrap div
	$('#pagination-wrap').children().css('display', 'none');
	
	//and show the first n (show_per_page) elements
	$('#pagination-wrap').children().slice(0, show_per_page).css('display', 'flex');
	
});

function previous(){
	
	new_page = parseInt($('#current_page').val()) - 1;
	//if there is an item before the current active link run the function
	if($('.pagination-block__link--active').prev('.pagination-block__link').length==true){
		go_to_page(new_page);
	}
	
}

function next(){
	new_page = parseInt($('#current_page').val()) + 1;
	//if there is an item after the current active link run the function
	if($('.pagination-block__link--active').next('.pagination-block__link').length==true){
		go_to_page(new_page);
	}
	
}
function go_to_page(page_num){
	//get the number of items shown per page
	var show_per_page = parseInt($('#show_per_page').val());
	
	//get the element number where to start the slice from
	start_from = page_num * show_per_page;
	
	//get the element number where to end the slice
	end_on = start_from + show_per_page;
	
	//hide all children elements of pagination-wrap div, get specific items and show them
	$('#pagination-wrap').children().css('display', 'none').slice(start_from, end_on).css('display', 'flex');
	
	/*get the page link that has longdesc attribute of the current page and add pagination-block__link--active class to it
	and remove that class from previously active page link*/
	$('.pagination-block__link[longdesc=' + page_num +']').addClass('pagination-block__link--active').siblings('.pagination-block__link--active').removeClass('pagination-block__link--active');
	
	//update the current page input field
	$('#current_page').val(page_num);
}