document.addEventListener('DOMContentLoaded', function() {
  const form = document.querySelector('.capture-form-data');
  const excludedFields = ['CRAFT_CSRF_TOKEN', 'action', 'redirect','g-recaptcha-response'];
  form.addEventListener('submit', function(e) {
    e.preventDefault(); // Prevent the default form submission initially
    const formData = new FormData(form);
    const dataObject = {};
    formData.forEach((value, key) => {
      if (!excludedFields.includes(key)) {
        dataObject[key] = value;
      }
    });    
    fetch('/web/saveToJSON.php', { 
      method: 'POST',
      body: JSON.stringify({ formData: JSON.stringify(dataObject) }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(response => response.json())
    .then(data => {
      if(!data.success) {
        alert('There was an error saving the form data.');
      } 
    })
    .catch(error => {
      alert('There was an error submitting the form.');
    });
    form.submit();
  });
});