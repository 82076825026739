$(document).ready(function () {
    //News filter
    $('.filter-block__btn').click(function () {
      //  $(this).toggleClass("filter-block__btn--active");
        var id = $(this).attr("value");
        if (id == "all") {
            $('.image-card-wrapper').show();
        } else {
            $('.image-card-wrapper').hide();
            $('.image-card-wrapper').each(function () {
                if ($(this).find('.image-card').attr('data-tag').indexOf(id) != -1) {
                    $(this).show();
                }
            });
        }
    });

    $('.filter-block__select').change( function () {
        var id = $(this).val();
        console.log(id)
        if (id == "all") {
            $('.image-card-wrapper').show();
        } else {
            $('.image-card-wrapper').hide();
            $('.image-card-wrapper').each(function () {
                if ($(this).find('.image-card').attr('data-tag').indexOf(id) != -1) {
                    $(this).show();
                }
            });
        }
    });

})